/* Stili per il toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 100px;
    height: 40px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 32px;
    width: 76px;
    top:1px;
    right: 1px;
    bottom: 1px;
    background-color: var(--light-grey)!important;
    border: 2px groove var(--light-grey-2);
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 10px;
}

input.sliderInput:checked + .slider {
    background-color: var(--light-grey)!important;
}

input.sliderInput:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input.sliderInput:checked + .slider:before {
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-22px);
}

input.sliderInput:checked  + .slider + .label {
    transform: translateX(-52px) translateY(-50%);
}

/* Stili per la label */
.label {
    position: absolute;
    top: 50%;
    left: 65px; /* Modifica la posizione orizzontale */
    transform: translateY(-50%);
    -webkit-transition: .4s;
    transition: .4s;
}

/* Personalizzazione dei colori */
.switch {
    width: 100px; /* Personalizza la larghezza */
}

.slider {
    background-color: var(--light-grey); /* Colore di base */
}

input.sliderInput:checked + .slider {
    background-color: var(--light-grey)!important; /* Colore quando attivo */
}

.slider:before {
    background-color: var(--light-grey-2); /* Colore del cerchio interno */
}

input:checked + .slider:before {
    background-color: var(--light-grey-2); /* Colore del cerchio interno quando attivo */
}