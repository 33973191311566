.readerContainer {
    position: relative;
    display: inline-block;
}

.innerReaderContainer {
    position:absolute;
    top: 10%;
    left: 18%;
    width: 64%;
    height: 52.7%;
    overflow:auto
}



/*Reader Test */
.numberCol {
    background-color: #dee7e7;
    border: 0.5px solid black;
    color: white;
    padding: 0;
}
.numberCol > span {
  justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    height: 100%;
    background: #498ce8;
}

