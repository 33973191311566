.header {
    z-index: 999 !important;
}

.header .mainNavLink a {
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;

}

.header > div:not(:last-child) {
    /*border-right: 3px groove #222;*/
}

.header .subMenu li, .header .subMenu p {
    border-bottom: 2px solid #fff !important;
}

.header .subMenu li:hover {
    background-color: var(--light-grey) !important;
    transform: scale(1.02);

}

.header .subMenu li:hover a {
    color: var(--blue) !important;

}

.header .mainNavLink {
    border-bottom: 5px solid #fff;
    border-top: 5px solid #fff;
}

.header .mainNavLink.active {
    /*background-color: var(--yellow);*/
    border-bottom: 5px solid var(--blue);
}

.header .active a {
    color: #000;
}