/*
.sensorImage {
    margin-left: -100px;
    margin-top: 80px
}

span.touch {
    top:190px!important;
    right: 18px!important;
}
span.usb {
    bottom:175px!important;
    left: -67px!important;
}
span.usbText {
    bottom:90px!important;
    left: -67px!important;
    width: 170px;
}
span.testStripPort {
    bottom:65px!important;
    left: -67px!important;
    width: 170px;
}
span.testStripPortText {
    bottom:5px!important;
    left: -67px!important;
    width: 200px;
}
*/

.gettingToknowImg.image-container {
    left: -30px;
    width: calc(100% + 57px);
}
