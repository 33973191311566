.applySensorImgTxt {
    position: absolute;
    width: 150px;
    left: 31%;
    border-left: 2px solid var(--blue);
    border-bottom: 2px solid var(--blue);
    bottom: -100px;
    height: 150px;
    align-content: end;
   padding-left: 10px;
}